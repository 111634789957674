export const environment = {
  production: true,
  apiUrl: 'https://api-bo-prod.lana.sparbankensyd.se/api',
  authServiceUrl: 'https://spb-common-api.internal.sparbankensyd.se/service',
  rtUrl: 'https://api-rt-prod.internal.sparbankensyd.se/api',
  domain: 'bo.internal.sparbankensyd.se',
  nextGeneration: false,
  testMode: false,
  developerMode: false
}
